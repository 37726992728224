<template>
  <div class="container compare-box">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-left">
          <div class="img">
            <img src="@/assets/img/img-n15.png" alt=""/>
          </div>
          共享数据
        </h1>
      </div>
    </div>

    <el-upload
        class="upload-tech"
        drag accept=".xlsx"
        :action="uploadApi">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">只能上传xlsx文件，且不超过500kb&nbsp;
        <a v-bind:href="baseResource+'/技术信息采集表模板.xlsx'" style="color:#007bff;">下载模板《技术信息采集表》</a></div>
    </el-upload>

    <b-card class="col-ms-12 explain" header="使用说明">{{this.instructions}}</b-card>
  </div>
</template>

<script>
import {baseApi,baseResource} from '@/config'

export default {
  name: "Share",
  data() {
    return {
      uploadApi: baseApi + '/techData/importExcel',
      baseResource: baseResource,
      instructions: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      this.queryInstructions()
    },
    queryInstructions(){
      let item = window.localStorage.getItem("instructions")
      this.instructions = JSON.parse(item)['share_data']
    },
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/less/data.less";

.upload-tech {
  text-align: center;
}

.explain {
  margin-top: 1%;
  height: 200px;
}

/deep/ .el-upload {
  width: 60%;
  height: 300px;

  .el-upload-dragger {
    width: 100%;
    height: 100%;
    padding-top: 5%;
  }
}
</style>